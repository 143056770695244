// Colors

$get-dark-gray: #3c3c3c !default;
$get-orange: #ffb902 !default;
$get-green: #3fb673 !default;
$get-gris-bleu: #19526c !default;
$get-gris-bleu-vert: #8fb0b6 !default;
$get-gris-clair: #f2f2f2 !default;

$ipsoon-background: #f3f4f6;
$ipsoon-filter-active-background-color: $get-orange;

$icon-stroke-color: #3f5c77;

// Outil de generation - Penser à exporter en Material 2
// http://mcg.mbitson.com/#!?mcgpalette0=%233f51b5

$md-paletteGrey: (
  50: #e8e8e8,
  100: #c5c5c5,
  200: #9e9e9e,
  300: #777777,
  400: #595959,
  500: #3c3c3c,
  600: #363636,
  700: #2e2e2e,
  800: #272727,
  900: #1a1a1a,
  A100: #f07474,
  A200: #ea4646,
  A400: #fd0000,
  A700: #e40000,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff
  )
);

$md-nav: (
  50: #e6eaed,
  100: #bfcbd2,
  200: #95a9b5,
  300: #6b8797,
  400: #4b6d80,
  500: #2b536a,
  600: #264c62,
  700: #204257,
  800: #1a394d,
  900: #10293c,
  A100: #78c1ff,
  A200: #45a9ff,
  A400: #1292ff,
  A700: #0086f7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$md-primary: (
  50: #e3eaed,
  100: #bacbd3,
  200: #8ca9b6,
  300: #5e8698,
  400: #3c6c82,
  500: #19526c,
  600: #164b64,
  700: #124159,
  800: #0e384f,
  900: #08283d,
  A100: #74c2ff,
  A200: #41abff,
  A400: #0e95ff,
  A700: #0088f4,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff
  )
);

$md-accent: (
  50: #fff7e1,
  100: #ffeab3,
  200: #ffdc81,
  300: #ffce4e,
  400: #ffc428,
  500: #ffb902,
  600: #ffb202,
  700: #ffaa01,
  800: #ffa201,
  900: #ff9301,
  A100: #ffffff,
  A200: #fff9f2,
  A400: #ffe1bf,
  A700: #ffd5a6,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$md-warn: (
  50: #fcebeb,
  100: #f9cccc,
  200: #f5aaaa,
  300: #f08888,
  400: #ed6f6f,
  500: #ea5555,
  600: #e74e4e,
  700: #e44444,
  800: #e13b3b,
  900: #db2a2a,
  A100: #ffffff,
  A200: #ffe7e7,
  A400: #ffb4b4,
  A700: #ff9b9b,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
