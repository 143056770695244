@use '@angular/material' as mat;

@include mat.core();

.app-chip {
  // background-color: mat.define-palette(mat.$indigo-palette, 500);
  padding: 0 1rem;
  height: 2rem;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 500;
  padding: 0 1rem;
  border-radius: 1rem;
  font-size: 1rem;
}
