.campaign-step-title {
  display: flex;
  justify-content: center;
  margin: 16px;
  font-size: 24px;
  font-weight: 500;
  max-width: 900px;
  text-align: center;
}

.collect-step-container {
  height: 100%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
  height: 100%;
}

.campaign-step-content {
  display: block;
  max-width: 900px;
  margin: 0 16px;
  background-color: white;
  padding: 16px;
  border-radius: 8px;
}

.campaign-step-content-caracs {
  display: block;
  max-width: 900px;
  border-radius: 8px;
}
