@import '../abstracts/variables';

.tool-panel-container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  flex: 1 1 0%;
}

.admin-board {
  width: 265px;
  margin-right: 4px;
}
