.get-button {
  &.red {
    color: #e20000;
  }
}

.file-add-button {
  .mdc-button-wrapper {
    display: flex;
    align-items: center;

    i {
      display: flex;
    }
  }
}

.mdc-button,
.mdc-stroked-button,
.mdc-flat-button {
  @media (max-width: 960px) {
    font-size: 16px !important;
  }
}

.mdc-stroked-button {
  .mdc-button-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    word-break: break-word;
  }
}

@media (max-width: 960px) {
  .mdc-button {
    padding: 0 8px !important;
    min-width: 40px !important;
  }
}
