/*
 * Default theme (only HTML tags customization here)
 */
@import 'variables';

html,
body {
  font-display: swap;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  user-select: none;
  -webkit-touch-callout: none;
  position: relative;
  background-color: whitesmoke;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

@media print {
  * {
    overflow: visible !important;
    print-color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
  }
  .page {
    page-break-after: always;
  }
  .hide-on-print {
    display: none !important;
  }
  .sidenav-page-content {
    margin-left: 0 !important;
  }
  .print-avoid-page-break-inside {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}

a {
  color: map-get($md-primary, 500);
  text-decoration: none;
  cursor: pointer;

  &:visited {
    color: map-get($md-primary, 500);
  }
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  &::placeholder {
    color: #a7adb9;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  background-color: transparent;
  overflow-x: overlay;
}

::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
  border: 3px solid transparent;
  border-radius: 6px;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-track-piece {
  width: 4px;
}

.ag-residence-sheet {
  max-width: 100%;
}

::-webkit-calendar-picker-indicator {
  margin-left: -15px;
}

.padding-around-container {
  padding: 0 16px;
}

.light-gray-color {
  color: #a7a9ac;
}

.composant-list-container {
  width: 100%;
  gap: 16px;

  .composant-groupe-title-container {
    background-color: map-get($md-primary, 500);
    border-radius: 4px;
    margin-bottom: 16px;
    height: 60px;
    padding: 16px;

    .composant-groupe-icon {
      i {
        font-size: 60px;
        display: flex;
        margin: 0 16px;
        color: map-get($md-accent, 500);
      }
    }

    .composant-groupe-title {
      color: white;
      font-size: 22px;
    }
  }

  .composant-element {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: #e5e5ef;
    }

    background: #f3f3f8;
    position: relative;

    .composant-element-title {
      line-height: 16px;
      white-space: break-spaces;
      text-align: center;
      width: 100%;
    }

    .composant-element-text {
      display: flex;
      justify-content: center;
    }

    .composant-element-subtitle {
      line-height: 16px;
      color: darkgrey;
    }

    .composant-element-badge {
      box-sizing: content-box;
      position: absolute;
      top: 70px;
      right: 35px;
      border-radius: 8px;
      padding: 0px 3px;
      line-height: 22px;
      min-width: 22px;
      height: 22px;
      font-weight: bold;
      justify-content: center;
      display: flex;
    }
  }
}

.caracteristic-form-field-mobile {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
}

.accent-500 {
  color: map-get($md-accent, 500);
}

.mobile-input {
  width: 90%;
  border: none;
  outline: 0;
  padding-top: 6px;
  font-size: 18px;
  background-color: transparent;

  &::placeholder {
    color: #a7adb9;
  }
}

.mobile-label {
  font-size: 16px;
  font-weight: 400;
  color: #a7adb9;
}

.icon-right {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 15px;
  color: #a7adb9;
}

.colorNeantSelectorOption {
  color: #a7adb9 !important;
}

.colorBlackSelectorOption {
  color: black;
}

.colorRedSelectorOption {
  color: red;
}

.mat-calendar-header {
  padding: 0px !important;
}

.full-height {
  height: 100%;
}

.progress-green-1 {
  background-color: #93dfa0;
  color: #027934;
}

.progress-green-2 {
  background-color: #c2e7a7;
  color: #687c59;
}

.progress-yellow {
  background-color: #fae87c;
  color: #9a8202;
}
.progress-orange {
  background-color: #ffcea3;
  color: #d86100;
}

.progress-red {
  background-color: #f4b7b8;
  color: #b80000;
}

.progress-grey {
  background-color: #f5f5f5;
  color: #727272;
}

@media (max-width: 960px) {
  lib-auth-form {
    .card {
      .card-header .logo {
        max-height: 128px !important;
      }
    }
  }
}

.form-field-max-width {
  max-width: 100%;
}

.information {
  color: white;
  background-color: map-get($md-primary, 700);
}

.attention {
  color: white;
  background-color: map-get($md-accent, 700);
}

.important {
  color: white;
  background-color: map-get($md-warn, 700);
}

.possibility-form-field-mobile {
  padding: 0 12px;
  min-height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 80%;
}

.close-dialog {
  cursor: pointer;
  border-radius: 18px;
  padding: 8px;
  margin-left: 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.shareUrl {
  text-decoration: none;
  margin-right: 16px;
}

@media print {
  body {
    min-width: 1000px !important;
  }
}

.icon-menu {
  margin-left: 4px;
}

.background-grey {
  background-color: whitesmoke;
}

.border-radius-4 {
  border-radius: 4px;
}

.padding-8 {
  padding: 8px;
}

.ifc-annotation {
  /* This is a trick to place the marker under the cursor */
  transform: translate(-50%, -50%);

  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
  background-color: map-get($md-accent, 500);
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: grab;
}
