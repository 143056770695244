/*
 * Classes used in src/app/core/configs/iconsHelper.config.ts
 */

.app-icon-star {
  color: orangered;
}

.app-icon-green {
  color: rgba(22, 145, 133, 1);
}

.app-icon-blue {
  color: rgba(2, 147, 191, 1);
}

.app-icon-red {
  color: map-get($md-warn, 500);
}

.app-icon-gray {
  color: rgba(102, 102, 102, 1);
}

.trash-icon {
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

  &:hover {
    color: map-get($md-warn, 500) !important;
  }
}

.display-star-empty {
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

  &:hover {
    color: #f3b80f !important;
  }
}

.display-star-full {
  color: #f3b80f !important;
}

.display-star-empty-library {
  color: #f3b80f !important;
}
