.reset-filter-button {
  position: absolute;
  bottom: 0;
  font-size: 15px;
  color: red;
  right: 4px;
}

.filter-icon {
  font-size: 18px;
}
