.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: white !important;
}

.mat-bottom-sheet-container {
  padding: 0 !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  max-height: 88vh !important;
  overflow: hidden !important;
}

.icon-ic_fluent_dismiss_24_regular {
  font-size: 20px;
}

.mat-step-header {
  pointer-events: none !important;
}

.mdc-list-item__primary-text {
  width: 100%;
}
