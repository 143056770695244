.clamp {
  display: flex !important;
  align-items: center !important;

  .ag-cell-wrapper {
    flex: 1;
  }

  .ag-cell-value {
    line-height: 22px !important;
    max-height: 44px !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }
}

@include ag.grid-styles(
  (
    theme: quartz
  )
);

$font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif !default;
// $font-size: 14px !default;
$header-background-color: white;
// $active: $cellance-primary-light !default;
// $selected-color: lighten($active, 0);
// $odd-row-background-color: white !default;
$row-height: 30px;

.ag-grid-border-16 {
  padding: 0 16px 8px 16px;
}

.ag-grid-border-8 {
  padding: 0 8px 4px 8px;
}

.ag-grid-top-border-8 {
  padding-top: 8px;
}

.ag-theme-quartz {
  --ag-borders: none;
  --ag-foreground-color: black;
  --ag-background-color: white;
  --ag-header-foreground-color: #737373;
  --ag-header-background-color: #e6e6e6;
  --ag-odd-row-background-color: white;
  /* --ag-header-cell-hover-background-color: #e7e8e9; */
  --ag-cell-horizontal-padding: 2px;
  --ag-row-border-color: #e6e6e6;
  --ag-font-size: 12px;
  --ag-font-family: 'Inter', sans-serif;
  --ag-control-panel-background-color: var(--ag-header-background-color);

  --ag-header-column-separator-width: 0px;

  // --ag-header-column-resize-handle-display: none;
  --ag-header-column-resize-handle-height: 50%;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-color: rgb(191, 191, 191);
  --ag-wrapper-border-radius: 4px;
  --ag-header-height: 32px;

  --ag-active-color: #{map-get($md-accent, 300)};
  --ag-range-selection-border-color: #{map-get($md-accent, 300)};
  --ag-grid-size: 6px;
  --ag-list-item-height: 34px;
  --ag-row-group-indent-size: 16px;
  // --ag-cell-widget-spacing: 8px;
  // --ag-header-cell-hover-background-color: #f6f8f9;
  --ag-row-hover-color: #f6f8f9;
  --ag-selected-row-background-color: #{map-get($md-accent, 50)};

  --custom-header-background-hover-color: #dadada;
  // background-color: $ipsoon-background;

  &.grey-background-ag-status-bar {
    .ag-status-bar {
      background-color: $ipsoon-background;
    }
  }

  .ag-header-group-cell,
  .ag-header-cell {
    .ag-header-cell-comp-wrapper {
      padding: 0 3px;
      font-weight: 600;

      height: calc(100% - 8px);
    }

    .ag-header-group-cell-label {
      padding: 0 3px;
      font-weight: 600;
    }
  }

  .ag-header-group-cell:not(.ag-column-resizing)
    + .ag-header-group-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
  .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:not(.ag-column-hover).ag-column-resizing,
  .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:not(.ag-column-hover):not(.ag-header-cell-moving):hover,
  .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:not(.ag-column-hover).ag-column-resizing,
  .ag-header-group-cell:first-of-type:not(.ag-header-cell-moving):hover,
  .ag-header-group-cell:first-of-type.ag-column-resizing,
  .ag-header-cell:not(.ag-column-hover):first-of-type:not(.ag-header-cell-moving):hover,
  .ag-header-cell:not(.ag-column-hover):first-of-type.ag-column-resizing {
    .ag-header-cell-comp-wrapper {
      background-color: var(--custom-header-background-hover-color);
      border-radius: 4px;
      transition: 0.5s;
    }
  }

  // hide filter icon in tab
  .ag-tab-selected,
  .ag-tabs-header {
    visibility: hidden;
    height: 0px;
  }

  .ag-sort-indicator-icon {
    color: $ipsoon-filter-active-background-color;

    i {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .ag-filter-icon {
    background-color: $ipsoon-filter-active-background-color;
    color: white;
    border-radius: 100%;
    height: 16px;
    width: 16px;
    min-width: 16px;
    line-height: 16px;
    text-align: center;

    i {
      display: flex;
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  .ag-status-bar {
    padding: 8px 0 0 0;
    background-color: $ipsoon-background;
  }

  .ag-row:not(.ag-row-group) {
    .ag-cell-border-bottom-and-right-forecast {
      border-top: solid 1px transparent !important;
      border-bottom: solid 1px #e58e1c !important;
      border-right: solid 1px #e58e1c !important;
      border-left: solid 1px transparent !important;
    }

    .ag-cell-border-bottom-and-left-forecast {
      border-top: solid 1px transparent !important;
      border-bottom: solid 1px #e58e1c !important;
      border-right: solid 1px #e8ecee !important;
      border-left: solid 1px #e58e1c !important;
    }
  }

  .ag-row-drag {
    // equivalent to gulp-34-handler
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0%29 --%3E%3Csvg version='1.1' id='Calque_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 34 34' style='enable-background:new 0 0 34 34;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23717171;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M23,3H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,3,23,3z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M23,13H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,13,23,13z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M23,23H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,23,23,23z'/%3E%3C/g%3E%3Cg%3E%3Cpath class='st0' d='M23,33H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h12c0.6,0,1,0.4,1,1S23.6,33,23,33z'/%3E%3C/g%3E%3C/svg%3E%0A") !important;
  }

  .ag-cell {
    text-overflow: clip;
  }

  .ag-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .ag-header-row {
      .ag-cell-label-container {
        position: relative;
        padding: 0;
      }

      .ag-header-cell-menu-button {
        position: absolute;
        bottom: 4px;
        height: 12px;
        cursor: pointer;
        background-color: var(--custom-header-background-hover-color);

        &:hover {
          box-shadow: 0 0 0 3px map-get($md-paletteGrey, 100);
          background-color: map-get($md-paletteGrey, 100);
        }
      }
    }
  }

  .ag-popup-editor {
    input {
      height: 30px;
    }

    textarea {
      min-height: 30px;
    }
  }
}

.multiple-lines-header {
  .ag-header-cell-label .ag-header-cell-text,
  .ag-header-group-text {
    white-space: normal !important;
    line-height: 13px;
  }
}

.multiple-lines-header-big {
  .ag-header-cell-label .ag-header-cell-text,
  .ag-header-group-text {
    white-space: normal !important;
    line-height: 13px;
    max-height: 65px;
  }
}

.align-right {
  text-align: end;
}

.greyed-row {
  background-color: map-get($md-paletteGrey, 50) !important;
}

.greyed-text {
  color: map-get($md-paletteGrey, 200) !important;
}

.icon-cell-text {
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 17px;
    padding-right: 5px;
  }
}

.icon-cell-disabled {
  cursor: auto !important;
}

.icon-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    font-size: 21px;
    padding-right: 5px;
    padding-top: 2px;
  }
}

.choix-cell {
  display: flex;

  i {
    display: flex;
    align-items: center;
  }

  div {
    display: flex;
    align-self: center;
  }
}

.color-cell {
  display: flex;

  i {
    display: flex;
    align-items: center;
  }
}

.svg-icon-cell {
  height: 100%;
  img {
    width: 28px;
    height: 100%;
  }
}

.svg-icon-cell-analyze-synthetic {
  height: 100%;
  img {
    width: 24px;
    margin-right: 4px;
  }
}

.ag-grid-row-disabled {
  background-color: rgba(0, 0, 0, 0.5) !important;
  cursor: not-allowed;
}

.ag-grid-align-right {
  text-align: right;
}

.ag-grid-align-center {
  text-align: center;
}

.ag-cell-inline-editing {
  padding: 0 !important;

  select {
    font-size: 12px;
  }
}

.ag-row-selected::before {
  z-index: -1;
}

.ag-row-group.ag-row-selected::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@for $i from 0 through 8 {
  ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-#{$i} {
    border-bottom: solid 1px var(--ag-row-border-color) !important;
    // color: map-get($md-nav, 500) !important;
    font-size: 14px;
    font-weight: 700;
    background-color: rgba(255 - ((15) - ($i * 5)), 255 - ((15) - ($i * 5)), 255 - ((15) - ($i * 5)));

    .ag-cell-value,
    .ag-group-value {
      overflow: initial;
    }

    .ag-icon {
      color: #151b26 !important;
    }

    &:hover {
      background-color: white !important;
    }
  }
}

ag-grid-angular:not(.no-grouping-style) .ag-row-group.ag-row-level-1 {
  .ag-icon {
    color: white;
  }
}

.ag-header-group-text,
.ag-header-cell-label .ag-header-cell-text {
  text-overflow: clip;
}

.ag-row-footer {
  font-weight: 500;
}

.ag-row-hover {
  .display-on-hover {
    display: block;
    color: rgba(0, 0, 0, 0.4);
    cursor: pointer;

    &:hover {
      color: black;
    }
  }
}

.ag-group-contracted,
.ag-group-expanded {
  i {
    display: flex;
  }
}

.display-on-hover {
  display: none;
}

.ag-align-right-placeholder-20 {
  text-align: right;

  &::after {
    content: '';
    padding-right: 20px !important;
  }
}

.ag-align-right-placeholder-10 {
  text-align: right;

  &::after {
    content: '';
    padding-right: 10px !important;
  }
}

.ag-align-right {
  text-align: right;
}

.ag-cell-blue {
  background-color: #cceeff;
  border-color: #00afff !important;
}

.ag-align-left {
  text-align: left;
}

.ag-align-center {
  text-align: center;
}

.ag-center-header {
  .ag-header-cell-text {
    margin: auto;
    text-align: center;
  }

  .ag-header-group-cell-label,
  .ag-header-cell-label {
    margin: auto;
    text-align: center;
    flex: unset;
  }
}

.ag-right-header {
  .ag-header-cell-text {
    margin-left: auto;
    text-align: right;
  }

  .ag-header-group-cell-label,
  .ag-header-cell-label {
    margin-left: auto;
    text-align: right;
    flex: unset;
  }
}

.ag-header-darkgrey {
  &:hover {
    background-color: darkgray !important;
    color: white !important;
  }

  background-color: darkgray;

  .ag-header-cell-text {
    color: white;
  }

  .ag-header-group-cell-label,
  .ag-header-cell-label {
    color: white;
  }
}

.ag-header-grey {
  &:hover {
    background-color: grey !important;
    color: white !important;
  }

  background-color: grey;
  color: white;
}

.ag-header-blue {
  &:hover {
    background-color: #39657b !important;
    color: white !important;
  }

  background-color: #39657b;
  color: white;

  .ag-header-group-cell-label,
  .ag-header-cell-label {
    color: white;
  }
}

.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.ag-cell-whitesmoke {
  background-color: whitesmoke;
}

.ag-cell-darkgrey {
  background-color: darkgrey;
  color: white;
}

.ag-cell-grey {
  background-color: rgb(210, 210, 210);
  color: black;
}

.ag-cell-italic {
  font-style: italic;
  font-weight: 100;
}

.ag-cell-bold {
  font-weight: 600;
}

.ag-side-buttons {
  display: none;
}

.ag-cell-center {
  .ag-selection-checkbox {
    margin: 0 !important;
  }

  display: flex;
  justify-content: center;
}

.ag-popup-editor {
  z-index: 999999999;
}

.ag-status-bar-left {
  flex: 1;
  overflow: hidden;
}

.ag-body-vertical-scroll,
.ag-body-horizontal-scroll {
  z-index: 3;
  background-color: white;
}

// Custom renderer or components or classes for ag-grid
.custom-ag-chart {
  height: 200px;
  width: 50%;
  max-width: 500px;
}

.auto-input {
  color: grey;
  font-style: italic;
}

.manual-input {
  color: black;
  font-weight: 700;
}

.checkbox-select-all {
  padding-left: 17px !important;
}

.grid-context-menu-icon {
  display: flex;
  place-content: center;
  font-size: 16px;
}

.display-cadenas {
  cursor: pointer;
}

.ag-background-color-caracs-required {
  background-color: #ffcfcf;
}

.ag-background-color {
  background-color: #f1f1f1;
}

.ag-neant {
  color: #a8adb9;
}

.ag-color-margin {
  margin-left: 4px;
}

.ag-chart-border-radius-4 {
  .ag-chart {
    border-radius: 4px;
  }
}

.prog-intervention-libelle {
  height: 100%;
  padding: 0 0 0 4px;
}

.coloured-pill {
  padding: 4px 6px;
  border-radius: 8px;
  margin-right: 4px;
}

// Color picker cell renderer
.grid-custom-renderer-container {
  width: 100%;
  height: 100%;
}

.grid-custom-renderer-color {
  border-radius: 4px;
}

#color_wrapper {
  display: flex;
}

#color_wrapper::before {
  content: '';
  position: absolute;
  border-radius: 3px;
  outline: black solid 2px;
  border: white solid 3px;
  height: 35px;
  width: 35px;
  pointer-events: none;
  background-color: inherit;
  visibility: visible;
  box-sizing: border-box !important;
}

#color_picker {
  opacity: 0;
  height: 35px;
  width: 35px;
  box-sizing: border-box !important;
  pointer-events: initial;
  visibility: visible;
}

#color_picker_alpha {
  filter: grayscale(100%);
  visibility: visible;
}

// Chargement des données
.ag-overlay-loading-center {
  align-items: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
}

.lds-ring div {
  box-sizing: border-box !important;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  margin: 2px;
  border: 2px solid map-get($md-primary, 500);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: map-get($md-primary, 500) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
