@use '@angular/material' as mat;
@import '../abstracts/variables';

@include mat.core();

$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif !default;

* {
  --mdc-typography-button-letter-spacing: 1;
  --mdc-typography-caption-line-height: 10px;
  --mdc-typography-caption-font-size: 10px;
  --mdc-dialog-subhead-color: white;
  --mdc-dialog-subhead-line-height: 24px;
  --mat-tab-header-label-text-letter-spacing: 1;
  --mdc-filled-button-label-text-tracking: 1;
  --mdc-text-button-label-text-tracking: 1;
  --mdc-outlined-button-label-text-tracking: 1;
  --mdc-protected-button-label-text-tracking: 1;
}

.mat-mdc-select-placeholder {
  color: #a7adb9 !important;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #fff;
}

$up-app-primary: mat.m2-define-palette($md-primary);
$up-app-accent: mat.m2-define-palette($md-accent, A200, A100, A400);
$up-app-warn: mat.m2-define-palette($md-warn);

$up-body-1: mat.m2-define-typography-level(
  $font-family: $font-family,
  $font-weight: 400,
  $font-size: 14px,
  $line-height: 24px,
  $letter-spacing: normal
);

$up-app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $up-app-primary,
      accent: $up-app-accent,
      warn: $up-app-warn
    ),
    typography:
      mat.m2-define-typography-config(
        $font-family: $font-family,
        $body-1: $up-body-1
      ),
    density: -2
  )
);

@include mat.all-component-themes($up-app-theme);

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 15px;
  padding-bottom: 0;
}

.mat-mdc-form-field-infix {
  min-height: 48px;
}

.mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
  width: 170px;
}

.mdc-text-field {
  padding: 0 4px !important;
}

.mdc-text-field .mdc-floating-label {
  transform: translateY(-30%);
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 4px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
  transform: translateY(-125%) scale(0.75) !important;
}

.mdc-text-field--disabled .mdc-floating-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-form-field-icon-suffix {
  padding: 0 !important;
}

.mat-mdc-form-field-icon-suffix,
[dir='rtl'] .mat-mdc-form-field-icon-prefix {
  align-self: baseline !important;
  text-wrap: nowrap;
  line-height: 20px !important;
}
.mat-mdc-form-field-icon-prefix {
  align-self: unset !important;
  text-wrap: nowrap;
}

.mat-mdc-form-field-bottom-align::before {
  height: 10px !important;
}

/*
 *
 * Forms
 *
 */

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87) !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined),
.mat-mdc-form-field-infix {
  padding-top: 20px;
  padding-bottom: 4px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: initial;
}

.mat-mdc-form-field-infix {
  min-height: 40px !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix,
.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 0;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: initial !important;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.87);
}

.mdc-tab {
  padding: 0 16px 8px 16px !important;
}

.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: initial !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 27px !important;
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

.mat-step-header {
  height: 40px !important;
}

.mdc-list {
  padding: 0 !important;
}

.mat-mdc-menu-content .mat-mdc-menu-item .mat-mdc-menu-item-text {
  display: flex;
  align-items: center;
}

::ng-deep .mat-stepper-header {
  pointer-events: none !important;
}

.cdk-virtual-scroll-spacer {
  height: 0 !important;
}

.cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane {
    width: unset !important;
  }
}

mat-form-field.w50 {
  .mat-mdc-text-field-wrapper {
    width: 50px;
  }
}

mat-form-field.w60 {
  .mat-mdc-text-field-wrapper {
    width: 60px;
  }
}

mat-form-field.w70 {
  .mat-mdc-text-field-wrapper {
    width: 70px;
  }
}

mat-form-field.w75 {
  .mat-mdc-text-field-wrapper {
    width: 75px;
  }
}

mat-form-field.w80 {
  .mat-mdc-text-field-wrapper {
    width: 80px;
  }
}

mat-form-field.w90 {
  .mat-mdc-text-field-wrapper {
    width: 90px;
  }
}

mat-form-field.w100 {
  .mat-mdc-text-field-wrapper {
    width: 100px;
  }
}

mat-form-field.w110 {
  .mat-mdc-text-field-wrapper {
    width: 110px;
  }
}

mat-form-field.w120 {
  .mat-mdc-text-field-wrapper {
    width: 120px;
  }
}

mat-form-field.w130 {
  .mat-mdc-text-field-wrapper {
    width: 130px;
  }
}

mat-form-field.w150 {
  .mat-mdc-text-field-wrapper {
    width: 150px;
  }
}

mat-form-field.w180 {
  .mat-mdc-text-field-wrapper {
    width: 180px;
  }
}

mat-form-field.w200 {
  .mat-mdc-text-field-wrapper {
    width: 200px;
  }
}

mat-form-field.w220 {
  .mat-mdc-text-field-wrapper {
    width: 220px;
  }
}

mat-form-field.w250 {
  .mat-mdc-text-field-wrapper {
    width: 250px;
  }
}

mat-form-field.w300 {
  .mat-mdc-text-field-wrapper {
    width: 300px;
  }
}

mat-form-field.w350 {
  .mat-mdc-text-field-wrapper {
    width: 350px;
  }
}

mat-form-field.w400 {
  .mat-mdc-text-field-wrapper {
    width: 400px;
  }
}

mat-form-field.w450 {
  .mat-mdc-text-field-wrapper {
    width: 450px;
  }
}

mat-form-field.w500 {
  .mat-mdc-text-field-wrapper {
    width: 500px;
  }
}

mat-form-field.pl20 {
  padding-left: 20px;
}

mat-form-field.badgeC {
  .mat-form-field-infix {
    border-top-style: none !important;
  }
}

.mdc-button {
  white-space: nowrap !important;
}

.mat-mdc-button-base {
  line-height: 20px !important;
}

.mat-mdc-form-field-flex {
  display: inline-flex;
  align-items: center;
}

.mat-mdc-option .mdc-list-item__primary-text {
  width: 100%;
}

/*---------------------------------------------------------------------------*/
// Default Material overrides

.mat-mdc-dialog-title {
  margin: 0 !important;
  padding: 20px;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 20px;
  font-weight: 500;
}

.center-icon-button {
  .mdc-button-wrapper {
    display: flex;

    i {
      display: flex;
      align-self: center;
      margin-right: 4px;
    }
  }
}

.mdc-dialog__title {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 16px 24px !important;
}

.mat-mdc-dialog-content {
  margin: 0 !important;
  padding: 5px 16px !important;
  max-height: 75vh !important;
}

.mat-mdc-dialog-actions {
  margin: 0 !important;
  padding: 12px 24px !important;
  float: right;
  justify-content: flex-end !important;
}

.mdc-dialog__title::before {
  display: none !important;
}

/*---------------------------------------------------------------------------*/
// Customs Material overrides

.mat-mdc-dialog-container {
  // To give full control to dialog-content div
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
}

@media screen and (max-width: 959px) {
  // Equivalent to 'tablet'
  .custom-dialog-fullscreen-sm {
    height: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    max-width: 100% !important;

    .mat-dialog-content {
      min-height: calc(100vh - 56px) !important; // 56px from header

      // Use to make paddings count in the calcul of height, else dialog do not respect viewport height
      box-sizing: border-box !important;
      display: flex;
      flex-direction: column;
    }

    .mat-dialog-container {
      min-height: 100vh !important;
    }
  }
}

.mdc-button__label {
  display: flex;
  align-items: center;
  position: static !important;
  padding: 4px 0 !important;
  gap: 4px;
}

.mat-mdc-list-item-unscoped-content {
  display: flex;
  justify-content: space-between;
}

.company-choice {
  width: 100%;
  box-sizing: border-box !important;
  padding: 0 8px 8px 8px;

  .mat-mdc-select-trigger {
    background-color: #39657b;
    box-sizing: border-box !important;
    border-radius: 8px;
    padding: 0 8px;

    .mat-mdc-select-arrow,
    .mat-mdc-select-value-text {
      color: #ffffff;
    }
  }
}

.menu-neuf {
  .mat-mdc-select-trigger {
    background-color: #6ab5b7;
  }
}

.menu-ancien {
  .mat-mdc-select-trigger {
    background-color: #39657b;
  }
}

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: rgb(25, 82, 108) !important;
  background-color: rgb(25, 82, 108) !important;
}

mat-checkbox {
  --mdc-checkbox-selected-checkmark-color: white;
}

.mdc-list-item__primary-text {
  min-height: 48px;
  align-content: center;
}
