//
@import './abstracts/mixins';
@import './abstracts/variables';
//
@import './base/reset';
//
@import './components/admin';
@import './components/analyze';
@import './components/auth';
@import './components/border';
@import './components/buttons';
@import './components/chip';
@import './components/content-panel';
@import './components/filter';
@import './components/icon-color';
@import './components/icon-pipe';
@import './components/section';
@import './components/strong';
@import './components/tool-panel';
@import './components/name-tag';
@import './components/info-panel';
@import './components/icons';
@import './components/color-picker';
@import './components/campaign';
@import './components/programmation';
//
@import './themes/theme';
@import './themes/tailwind';
@import './themes/material';
//
// TODO: Check les possibilités pour réduire les 2 fichiers suivants
@import './vendors/ag-grid'; // build: ~181.99Kb
@import './vendors/material'; // build: ~88.81Kb
//
@import '../assets/fonts/fluentui-font/icons';

@import '@angular/cdk/overlay-prebuilt.css';
