.programmation-filters-container {
  padding: 16px;
}

.grid-group-add-button {
  max-height: 32px;
  max-width: 32px;
  color: #2b526c !important;
  border: none !important;
}

.grid-group-add-button-icon {
  font-size: 24px;
  display: flex;
  height: 100%;
  place-items: center;
  place-content: center;
}

.grid-group-label {
  overflow: hidden;
  text-overflow: ellipsis;
  align-content: center;
}

.grid-group-container {
  padding: 0 16px;
}

.grid-group-expand-icon {
  margin-right: 12px;
  cursor: pointer;

  i {
    display: flex;
    align-items: center;
  }
}
