.app-content-panel {
  overflow: auto;
}

.app-left-content-panel {
  width: 490px;
}

.fill-remaining-space {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: white;
  overflow: auto;
}
