.app-section {
  padding: 10px 25px 10px 25px;
  margin: 0 10px 25px 0;
  background-color: white;
  border-radius: 1px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.16);
  display: block;

  .app-section-title {
    margin: 0 0 15px 0 !important;
    font-size: 22px;
    line-height: 40px;
    font-weight: 500;
    position: relative;
    color: map-get($md-primary, 500);
    .material-icons-outlined {
      margin-right: 15px;
      vertical-align: bottom;
    }
    &::before {
      content: '';
      width: 58px;
      height: 2px;
      background: map-get($md-primary, 500);
      position: absolute;
      bottom: -10px;
      left: -29px;
    }
  }

  .app-sub-section {
    .app-sub-section-title {
      line-height: 50px;
      margin: 0;
      font-size: 18px;
      margin-top: 8px;
    }

    a {
      color: map-get($md-primary, 700);
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

@media (min-width: 1350px) {
  .app-section {
    max-width: calc(100% - 250px);
  }
  .toc-container {
    width: 200px;
    position: fixed;
    top: 150px;
    right: 0;
    bottom: 12px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.app-content {
  overflow: auto;
  height: 100%;
}
